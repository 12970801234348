import { h, render } from 'preact';

import 'modern-normalize';
import './site.css';

import './i18n';
import { MeetingProvider } from './use-meeting';
import App from './components/App';

const mount = document.getElementById('root');
render(
  <MeetingProvider>
    <App />
  </MeetingProvider>,
  mount,
  mount.firstChild);
