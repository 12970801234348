import { h, Fragment } from 'preact';
import { useTranslation } from 'react-i18next';

import useMeeting from '../../use-meeting';
import useTimestamp from '../../use-timestamp';

import style from './style.css';

import Clock from 'feather-icons/dist/icons/clock.svg';

const MeetingStatus = () => {
  const { t } = useTranslation();
  const { id, rate, meetingStart, checkpoint } = useMeeting();
  const displayTime = useTimestamp(100, !!id);

  if (!id) {
    return null;
  }

  const currentCost = Math.max(0, checkpoint.value +
    rate * (displayTime - checkpoint.time) / 1000);
  const meetingLength = Math.max(0, displayTime - meetingStart);

  return <div class={ style.meetingStatus }>
    <div>
      { t('MeetingStatus.cost', { cost: currentCost }) }
    </div>
    <div class={ style.elapsedTime }>
      <Clock alt="" />
      { t('MeetingStatus.elapsed-time', { time: meetingLength }) }
    </div>
  </div>;
};

export default MeetingStatus;
