import { h, createContext } from 'preact';
import { useContext, useState, useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';

import MeetingConnector from './meeting-connector';

const meetingConnector = new MeetingConnector();
const Context = createContext();

const useMeeting = () => useContext(Context);

const MeetingProvider = ({ children }) => {
  const [meeting, setMeeting] = useState(meetingConnector.toState());

  useEffect(() => {
    const handler = () => {
      setMeeting(meetingConnector.toState());
    };
    meetingConnector.on('change', handler);

    return () => meetingConnector.off('change', handler);
  }, [setMeeting]);

  return <Context.Provider value={ meeting }>
    { children }
  </Context.Provider>;
};

MeetingProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default useMeeting;
export { MeetingProvider };
