import { useState, useEffect } from 'preact/hooks';

const useTimestamp = (refreshInterval, running) => {
  const [time, setTime] = useState(Date.now());
  useEffect(() => {
    if (!running) {
      return;
    }

    const id = setInterval(() => setTime(Date.now()), refreshInterval);

    return () => clearInterval(id);
  }, [running, setTime, refreshInterval]);

  return time;
};

export default useTimestamp;
