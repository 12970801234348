import { h } from 'preact';
import { useTranslation } from 'react-i18next';

import useLocation from '../../use-location';
import useMeeting from '../../use-meeting';
import useTimestamp from '../../use-timestamp';

import style from './style.css';

const MeetingLink = () => {
  const { t } = useTranslation();
  const { browser } = useLocation();
  const { id, expires } = useMeeting();
  const displayTime = useTimestamp(1000, id && (expires > 0));

  if (!id) {
    return null;
  }

  const joinLink = browser.href;
  const expiresIn = expires - displayTime;

  return <div class={ style.meetingLink }>
    <span>
      { t('MeetingLink.join-link-label') } <a href={ joinLink }>
        { joinLink }
      </a>
    </span> { (expiresIn > 0) && <span>
      { t('MeetingLink.expires-message', { expiresIn }) }
    </span> }
  </div>;
};

export default MeetingLink;
