import { h, Fragment } from 'preact';
import { useState, useCallback, useRef, useLayoutEffect } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useMeeting from '../../use-meeting';

import style from './style.css';

import Check from 'feather-icons/dist/icons/check-circle.svg';
import Cancel from 'feather-icons/dist/icons/x-circle.svg';

const EditMeetingName = ({ name, onClose }) => {
  const { t } = useTranslation();
  const meeting = useMeeting();
  const [nameValue, setNameValue] = useState(name);
  const inputRef = useRef();
  const placeholderRef = useRef();

  const handleCancel = useCallback(event => {
    event.preventDefault();
    onClose();
  }, [onClose]);
  const handleSubmit = useCallback(async event => {
    event.preventDefault();

    if (nameValue === '') {
      return;
    }

    if (nameValue !== name) {
      try {
        await meeting.rename(nameValue);
      } catch (error) {
        console.error(error);
      }
    }

    onClose();
  }, [name, nameValue, onClose]);
  const handleChange = useCallback(event => {
    setNameValue(event.target.value);
  }, [setNameValue]);
  const handleKeyup = useCallback(event => {
    if (event.keyCode === 27) { // Escape
      handleCancel(event);
    }
  }, [handleCancel]);

  useLayoutEffect(() => {
    inputRef.current.focus();
    inputRef.current.style.width = placeholderRef.current.clientWidth + 'px';
  });

  return <form class={ style.form } onSubmit={ handleSubmit }>
    <input type="text"
      value={ nameValue }
      ref={ inputRef }
      onChange={ handleChange }
      onKeyup={ handleKeyup } />
    <div class={ style.placeholder } ref={ placeholderRef }>{ nameValue }</div>
    <button class="icon" type="submit">
      <Check alt={ t('EditMeetingName.change-alt') } />
    </button>
    <button class="icon" onClick={ handleCancel }>
      <Cancel alt={ t('EditMeetingName.cancel-alt') } />
    </button>
  </form>;
};

EditMeetingName.propTypes = {
  name: PropTypes.string,
  onClose: PropTypes.function
};

export default EditMeetingName;
