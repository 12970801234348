import { h, Fragment } from 'preact';
import { useRef, useCallback } from 'preact/hooks';
import { useTranslation } from 'react-i18next';

import useLocation from '../../use-location';
import useMeeting from '../../use-meeting';

import style from './style.css';

const StartMeeting = () => {
  const { t } = useTranslation();
  const meeting = useMeeting();
  const { history } = useLocation();
  const meetingName = useRef(null);
  const handleSubmit = useCallback(async event => {
    event.preventDefault();

    const name = meetingName.current.value;

    if (name.length === 0) {
      return;
    }

    try {
      const id = await meeting.start(name);
      history.push(id);
    } catch (error) {
      console.error(error);
    }
  }, [meetingName, meeting.start, history]);

  if (meeting.id) {
    return null;
  }

  return <>
    <form class={ style.container }
      onSubmit={ handleSubmit }>
      <input type="text"
        placeholder={ t('StartMeeting.meeting-name-placeholder') }
        ref={ meetingName } />
      <button type="submit">
        { t('StartMeeting.button-label') }
      </button>
    </form>
  </>;
};

export default StartMeeting;
