import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import resources from './locale.yaml';

Object.assign(i18n, {
  getCurrencyCode() {
    return this._currencyCode || this.t('config:currency-code');
  },

  setCurrencyCode(code) {
    this._currencyCode = code;
  }
});

const formatters = {
  currency(value, lng) {
    const formatter = new Intl.NumberFormat(lng, {
      style: 'currency',
      currency: i18n.getCurrencyCode()
    });
    return formatter.format(value);
  },

  elapsedTime(value) {
    const seconds = Math.floor(value / 1000) % 60;
    const minutes = Math.floor(value / (60 * 1000)) % 60;
    const hours = Math.floor(value / (60 * 60 * 1000));

    return [
      hours,
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0')
    ].join(':');
  },

  timeInterval(value, lng) {
    const seconds = Math.ceil(value / 1000);
    const formatter = new Intl.RelativeTimeFormat(lng, {
      style: 'long'
    });

    return seconds < 60
      ? formatter.format(seconds, 'second')
      : formatter.format(Math.ceil(value / 60000), 'minute');
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: (process.env.NODE_ENV !== 'production'),
    interpolation: {
      escapeValue: false,
      format(value, format, lng) {
        return formatters[format]
          ? formatters[format](value, lng)
          : value;
      }
    },
    resources
  });

export default i18n;
