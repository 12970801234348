import { h, Fragment } from 'preact';
import { useEffect } from 'preact/hooks';
import Helmet from 'preact-helmet';

import useLocation from '../../use-location';
import useMeeting from '../../use-meeting';

import StartMeeting from '../StartMeeting';
import MeetingName from '../MeetingName';
import MeetingStatus from '../MeetingStatus';
import MeetingLink from '../MeetingLink';
import JoinMeeting from '../JoinMeeting';

const App = () => {
  const { location, history } = useLocation();
  const { id, name, expires, attend, leave } = useMeeting();

  const meetingId = location.pathname.slice(1);
  if (meetingId && id !== meetingId) {
    attend({ id: meetingId, rate: 0 }).catch(error => console.error(error));
  }

  if (!meetingId && id) {
    leave().catch(error => console.error(error));
  }

  useEffect(() => {
    if (!expires || expires === -1) {
      return;
    }

    const id = setTimeout(() => {
      history.push('');
    }, expires - Date.now());

    return () => clearTimeout(id);
  }, [expires, history]);

  const pageTitle = [
    name,
    'Meeting Cost'
  ].filter(Boolean).join(' - ');

  return <>
    <Helmet title={ pageTitle } />

    <StartMeeting />
    <MeetingName />
    <MeetingStatus />
    <MeetingLink />
    <JoinMeeting />
  </>;
};

export default App;
