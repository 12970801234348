import { useState, useEffect } from 'preact/hooks';
import { createHashHistory } from 'history';

const history = createHashHistory({ hashType: 'noslash' });

const useLocation = () => {
  const [location, setLocation] = useState(history.location);
  useEffect(() => {
    return history.listen(newLocation => setLocation(newLocation));
  }, [setLocation]);

  return {
    browser: document.location,
    history,
    location
  };
};

export default useLocation;
