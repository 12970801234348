import { h, Fragment } from 'preact';
import { useState, useCallback } from 'preact/hooks';
import { useTranslation } from 'react-i18next';

import useMeeting from '../../use-meeting';
import EditMeetingName from '../EditMeetingName';

import style from './style.css';

import Edit from 'feather-icons/dist/icons/edit-3.svg';

const MeetingName = () => {
  const { t } = useTranslation();
  const { id, name } = useMeeting();
  const [ editing, setEditing ] = useState(false);
  const handleEdit = useCallback(event => {
    event.preventDefault();
    setEditing(true);
  }, [setEditing]);
  const handleClose = useCallback(() => setEditing(false), [setEditing]);

  if (!id) {
    return null;
  }

  return <h1 class={ style.meetingName }>
    { !editing && <>
      <div>{ name }</div> <button class={ ['icon', style.editName].join(' ') }
        onClick={ handleEdit }>
        <Edit alt={ t('MeetingName.edit-alt') } />
      </button>
    </> }
    { editing && <EditMeetingName name={ name } onClose={ handleClose } /> }
  </h1>;
};

export default MeetingName;
