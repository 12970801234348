import { h, Fragment } from 'preact';
import { useRef, useCallback, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';

import useMeeting from '../../use-meeting';

import style from './style.css';

const JoinMeeting = () => {
  const { t } = useTranslation();
  const { id, attending, attend } = useMeeting();
  const [rate, setRate] = useState(Number(localStorage.getItem('saved-rate')));
  const [hasSavedRate, setHasSavedRate] = useState(!!rate);

  const salaryRef = useRef(null);
  const vacationRef = useRef(null);
  const hoursRef = useRef(null);
  const saveRef = useRef(null);

  const handleChange = useCallback(() => {
    const salary = parseFloat(salaryRef.current.value);
    const vacation = parseFloat(vacationRef.current.value);
    const hours = parseInt(hoursRef.current.value || 40);

    const rate = salary / ((52 - vacation) * hours * 3600);

    if (!isFinite(rate) || isNaN(rate) || rate <= 0) {
      setRate(null);
    } else {
      setRate(rate);
    }
  }, [setRate]);
  const handleSubmit = useCallback(async event => {
    event.preventDefault();

    if (!rate) {
      return;
    }

    if (saveRef.current) {
      if (saveRef.current.checked) {
        localStorage.setItem('saved-rate', rate);
        setHasSavedRate(true);
      } else {
        localStorage.removeItem('saved-rate');
        setHasSavedRate(false);
      }
    }

    try {
      await attend({ id, rate });
    } catch (error) {
      console.error(error);
    }
  }, [attend, id, rate, setHasSavedRate]);
  const handleSetRate = useCallback(() => {
    setRate(null);
    setHasSavedRate(false);
  }, [setRate, setHasSavedRate]);

  if (!id || attending) {
    return null;
  }

  return <>
    { hasSavedRate && <div class={ style.joinMeetingSaved }>
      <span>{ t('JoinMeeting.saved-rate-copy', { rate }) }</span>
      <button type="submit" onClick={ handleSubmit }>
        { t('JoinMeeting.button-label') }
      </button>
      <button onClick={ handleSetRate }>
        { t('JoinMeeting.change-button-label') }
      </button>
    </div> }
    { !hasSavedRate && <div class={ style.joinMeeting }>
      <form  autocomplete="off" onSubmit={ handleSubmit }>
        <label for="salary">{ t('JoinMeeting.salary-label') }</label>
        <input id="salary"
          type="text"
          placeholder="0.00"
          ref={ salaryRef }
          onChange={ handleChange } />

        <label for="vacation">{ t('JoinMeeting.vacation-label') }</label>
        <input id="vacation"
          type="text"
          ref={ vacationRef }
          onChange={ handleChange } />

        <label for="hours">{ t('JoinMeeting.hours-label') }</label>
        <input id="hours"
          type="text"
          placeholder="40"
          ref={ hoursRef }
          onChange={ handleChange } />

        <label>
          <input type="checkbox" ref={ saveRef } /> Save rate
        </label>

        <button type="submit">
          { t('JoinMeeting.button-label') }
        </button>
      </form>

      <div dangerouslySetInnerHTML={{
        __html: t('JoinMeeting.terms-copy')
      }} />
    </div> }
  </>;
};

export default JoinMeeting;
