import Emitter from 'component-emitter';
import io from 'socket.io-client';

class MeetingConnector {
  constructor() {
    this._meeting = {};
    this._attending = false;

    this._socket = io();
    this._socket.on('connect', () => this._handleConnect());
    this._socket.on('status', meeting => this._handleStatus(meeting));
  }

  get id() {
    return this._meeting.id;
  }

  get name() {
    return this._meeting.name;
  }

  get rate() {
    return this._meeting.rate;
  }

  get meetingStart() {
    return this._meeting.meetingStart;
  }

  get checkpoint() {
    return { ...this._meeting.checkpoint };
  }

  get attending() {
    return this._attending;
  }

  get expires() {
    return this._meeting.expires;
  }

  toState() {
    return {
      id: this.id,
      name: this.name,
      rate: this.rate,
      meetingStart: this.meetingStart,
      checkpoint: this.checkpoint,
      expires: this.expires,
      attending: this.attending,
      start: this.start.bind(this),
      attend: this.attend.bind(this),
      leave: this.leave.bind(this),
      rename: this.rename.bind(this)
    };
  }

  async _handleConnect() {
    try {
      if (!this.id) {
        console.debug('No need to rejoin. Not in meeting');
      } else if (this.lastSocketId === this._socket.id) {
        console.debug('No need to rejoin. Socket ID unchanged');
      } else {
        await this.rejoin();
        console.debug('Rejoin complete');
      }
    } catch (error) {
      console.error(error);
    }

    this.lastSocketId = this._socket.id;
  }

  _handleStatus(meeting) {
    this._updateMeeting(meeting);
  }

  _updateMeeting(meeting) {
    if (this.id === undefined && meeting.id === undefined) {
      return;
    }

    this._meeting = meeting;
    this.emit('change');
  }

  _updateAttending(attending) {
    if (this._attending === attending) {
      return;
    }

    this._attending = attending;
    this.emit('change');
  }

  async _socketCall(message, { timeout, args }) {
    args = args || [];
    timeout = timeout || 1000;

    return new Promise((resolve, reject) => {
      if (timeout > 0) {
        setTimeout(() => reject(new Error('Timeout')), timeout);
      }

      this._socket.emit(message, ...args, (error, result) => {
        if (error) {
          return reject(error);
        }

        resolve(result);
      });
    });
  }

  async start(name) {
    return this._socketCall('start', { args: [name] });
  }

  async attend({ id, rate }) {
    try {
      const result = await this._socketCall('attend', {
        args: [id, rate]
      });

      this._updateMeeting(result.meeting);
      this._updateAttending(result.rate !== 0);
    }
    catch (error) {
      this._updateMeeting({});
      this._updateAttending(false);
      throw error;
    }
  }

  async rejoin() {
    if (!this.lastSocketId) {
      throw new Error('Cannot rejoin. No previous socket ID');
    }

    const meeting = await this._socketCall('rejoin', {
      args: [this.id, this.lastSocketId]
    });

    this._updateMeeting(meeting);
  }

  async leave() {
    if (!this.id) {
      throw 'Cannot leave meeting without watching';
    }

    const id = this.id;

    this._updateMeeting({});
    this._updateAttending(false);

    return this._socketCall('leave', {
      args: [id]
    });
  }

  async rename(name) {
    if (!this.id) {
      throw new Error('Cannot rename meeting without watching');
    }

    return this._socketCall('rename', {
      args: [this.id, name]
    });
  }
}

Emitter(MeetingConnector.prototype);

export default MeetingConnector;
